import { IProductInventoryInformation } from '@msdyn365-commerce-modules/retail-actions';
import { IActionContext } from '@msdyn365-commerce/core';
import { AttributeValue, Cart } from '@msdyn365-commerce/retail-proxy';
import { getQuantityThreshold, percentageValue } from '../utilities/helpers';

export const checkLineInventory = async (
    ctx: IActionContext,
    productRecId: number,
    productQuantity: number,
    inventory: IProductInventoryInformation[],
    cart?: Cart,
    unitOfMeasure?: string
) => {
    let lineQuantity = productQuantity;
    let originalQuantity = productQuantity;
    let quantityToSubtract = 0;
    const quantityThreshold = ctx.requestContext.app.config.quantityThreshold;
    const upperQuantityThreshold = ctx.requestContext.app.config.upperQuantityThreshold
    const upperWeightThresholdKG = ctx.requestContext.app.config.upperWeightThresholdKG
 
    const quantityThresholdAttributeName = ctx.requestContext.app.config.quantityThresholdAttributeName;
    const cartline = cart?.CartLines?.find(cartLine => cartLine.ProductId === productRecId);

    if (cartline && cartline.Quantity) {
        const thresholdAttributeValue: AttributeValue | undefined = cartline?.AttributeValues?.find(
            attr => attr.Name === quantityThresholdAttributeName
        );
        // eslint-disable-next-line radix
        quantityToSubtract = parseInt(thresholdAttributeValue?.TextValue || '0');
        originalQuantity = cartline.Quantity - quantityToSubtract;
        originalQuantity += productQuantity;
    }
    let thresholdQuantity = 0;
    if (quantityThreshold > 0 && upperQuantityThreshold > 0 ) {
        const weightCheckOnThreshold = getQuantityThreshold(originalQuantity ,  upperWeightThresholdKG) ? quantityThreshold : upperQuantityThreshold;
        const quantityToAdd = unitOfMeasure === 'pcs' ? 0 : percentageValue(originalQuantity, weightCheckOnThreshold) ;
        thresholdQuantity = quantityToAdd;
        lineQuantity += quantityToAdd;
        lineQuantity -= quantityToSubtract;
    }
    const lineInventory = inventory.find(availability => availability.ProductAvailableQuantity.ProductId === productRecId);
    const totalAvailable = lineInventory?.ProductAvailableQuantity?.AvailableQuantity || 0;

    const res = {
        isValidQuantity: totalAvailable !== 0 && originalQuantity + thresholdQuantity <= totalAvailable,
        lineQuantity,
        thresholdQuantity
    };
    return res;
};
